


   body {
    background-color: white;
    color: black;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .SignupPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .logo {
    font-size: 50px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .SignupPage input[type="email"],
  .SignupPage input[type="password"],
  .SignupPage select {
    width: 400px;
    padding: 15px;
    margin: 15px 0;
    border: 1px solid black;
    border-radius: 4px;
    background-color: white;
    color: black;
    font-size: 16px;
  }
  
  .SignupPage button {
    padding: 15px 30px;
    margin: 15px 10px;
    border: 1px solid black;
    border-radius: 4px;
    background-color: black;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .SignupPage button:hover {
    background-color: white;
    color: black;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .errorMessage {
    color: red;
    font-size: 16px;
  }
  
 
  @media (max-width: 600px) {
    .SignupPage input[type="email"],
    .SignupPage input[type="password"],
    .SignupPage select {
      width: 90%;
    }
  
    .logo {
      font-size: 40px;
      top: 5px;
      right: 5px;
    }
  
    .SignupPage button {
      width: 90%;
    }
  }
  