/* Hero.css

.hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background: transparent;
    color: #fff;
    text-align: center;
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .hero-container {
      height: auto;
      padding: 10px;
    }
  }
  
  .hero-title {
    font-size: 4em;
    margin-bottom: 0.5em;
    line-height: 1.32;
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 3em;
      margin-left: 0;
    }
  }
  
  .fade-in {
    animation: fadeIn 1s ease-in-out forwards;
    opacity: 1;
  }
  
  .fade-out {
    animation: fadeOut 1s ease-in-out forwards;
    opacity: 0;
  }
  
  .word-container {
    display: inline-block;
    position: relative;
    height: 1em;
    vertical-align: baseline;
  }
  
  .dynamic-word {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .hero-subtitle {
    font-size: 2.5em;
    margin-bottom: 1em;
  }
  
  @media (max-width: 768px) {
    .hero-subtitle {
      font-size: 2em;
    }
  }
  
  .hero-button {
    padding: 15px 30px;
    font-size: 1.5em;
    color: white;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .hero-button:hover {
    background-color: white;
    color: black;
  }
  
  @media (max-width: 768px) {
    .hero-button {
      font-size: 1.2em;
      padding: 10px 20px;
    }
  }
   */

   /* Hero.css */




   


  /* Ensure the body and html do not overflow the viewport */
html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
  }
  
  /* Hero container styling */
  .hero-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background: transparent;
    color: #fff;
    text-align: center;
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .hero-container {
      height: auto;
      padding: 10px;
    }
  }
  
  /* Hero title styling */
  .hero-title {
    font-size: 4em;
    margin-bottom: 0.5em;
    line-height: 1.32;
    position: relative; /* Needed for positioning the dynamic word */
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 3em;
      margin-left: 0;
    }
  }
  
  /* Word container styling */
  .word-container {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100%; /* Position it below the title */
    margin-top: 10px; /* Adjust this value to create the desired gap */
  }
  
  .dynamic-word {
    display: inline-block;
    animation: fadeIn 1s ease-in-out forwards;
    opacity: 1;
  }
  
  .fade-in {
    animation: fadeIn 1s ease-in-out forwards;
    opacity: 1;
  }
  
  .fade-out {
    animation: fadeOut 1s ease-in-out forwards;
    opacity: 0;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  /* Hero subtitle styling */
  .hero-subtitle {
    font-size: 2.5em;
    margin-top: 100px;
    margin-bottom: 1em;
  }
  
  @media (max-width: 768px) {
    .hero-subtitle {
      font-size: 2em;
    }
  }
  
  /* Hero button styling */
  .hero-button {
    padding: 15px 30px;
    font-size: 1.5em;
    color: white;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .hero-button:hover {
    background-color: white;
    color: black;
  }
  
  @media (max-width: 768px) {
    .hero-button {
      font-size: 1.2em;
      padding: 10px 20px;
    }
  }
  