body {
    background-color: white;
    color: white !important;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .MainPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .main-logo {
    font-size: 64px;
    font-weight: bold;
    text-align: center;
  }
  
  button {
    padding: 15px 30px;
    margin: 15px 10px;
    border: 1px solid black;
    border-radius: 4px;
    background-color: black;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  button:hover {
    background-color: white;
    color: black;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .SignOutButton {
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 4px;
    background-color: black;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: inline-block;
    width: auto;
  }
  
  .SignOutButton:hover {
    background-color: white;
    color: black;
  }
  
  .sign-out-container {
    position: absolute;
    top: 30px;
    left: 20px;
    /* z-index: 1000; */
  }  
  .your-boards-button {
    position: absolute;
    top: 30px;
    right: 10px;
  }
  
  .boards-list-container {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    transition: right 0.3s ease-in-out;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .boards-list-container.open {
    right: 0;
  }
  
  .boards-list {
    list-style-type: none;
    padding: 0;
  }
  
  .boards-list li {
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .boards-list li:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .close-boards-list {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
  .new-answer-notification {
    display: inline-block;
    background-color: #ff9900;
    color: white;
    font-size: 0.8em;
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 10px;
    margin-left: 10px;
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  