
   body {
    background-color: white;
    color: black;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .QuestionBoard {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .logo1 {
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .QuestionInput {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
  
  textarea {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid black;
    border-radius: 4px;
    height: auto;
    min-height: 40px;
    resize: none;
    background-color: white;
    color: black;
  }
  
  button {
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 4px;
    background-color: black;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  button:hover {
    background-color: white;
    color: black;
  }
  
  .SignOutButton {
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 4px;
    background-color: black;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: inline-block; /*Ensure it is not a block element*/
    width: auto; /* Ensure it does not span full width */
  }
  
  .SignOutButton:hover {
    background-color: white;
    color: black;
  }

  .sign-out-container {
    display: inline-block; /* Ensure it does not span full width */
  }
  
  .ToggleViewButton {
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 4px;
    background-color: black;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: inline-block; /* Ensure it is not a block element */
    width: auto; /* Ensure it does not span full width */
  }
  
  .ToggleViewButton:hover {
    background-color: white;
    color: black;
  }
  
  .QuestionsList {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 60px; 
  }
  
  .Question {
    margin-bottom: 20px;
  }
  
  hr {
    border: 0;
    border-top: 1px solid black;
  }
  
  .errorMessage {
    color: red;
  }

  .host-controls {
    display: none;
    margin-top: 10px;
  }
  
  .host-controls.visible {
    display: block;
  }

  